<template>
  <div id="nav">
    <router-link to="/" class="link-logo"><img src="./assets/img/logo-CA@2x.png" alt="Crédit Agricole" width="46" class="logo" v-on:click="toggleHome"></router-link>
    <a href="#" class="btn-menu" v-on:click="toggleMenu"></a>
    <div :class="[ menuOn ? 'd-block' : 'd-none', 'menu']">
      <a href="#" class="link-logo" v-on:click="toggleMission">VOTRE MISSION</a><span class="px-sm-1 px-md-2 px-lg-3">|</span>
      <a href="/uploads/GUIDE_FOURRAGE_CA.pdf" target="_blank">LE GUIDE DE PRÉVENTION</a><span class="px-sm-1 px-md-2 px-lg-3">|</span>
      <a href="#" class="link-logo" v-on:click="toggleSupport">LE CRÉDIT AGRICOLE VOUS ACCOMPAGNE</a>
    </div>
    <span class="btn-close" v-if="menuOn" v-on:click="toggleMenu"></span>
  </div>

  <router-view @quiz-loaded="toggleMission" @home-loaded="toggleHome" />

  <footer class="footer mb-4">
    <router-link to="/Mentions" class="" v-on:click="toggleMentions">Mentions légales</router-link> |
    <router-link to="/Confidentialite" class="" v-on:click="toggleProtection">Protection des données personnelles</router-link> |
    <router-link to="/Cookies" class="" v-on:click="toggleCookies">Politique des cookies</router-link> |
    <a href="#" onclick="tC.privacyCenter.showPrivacyCenter();return false" v-on:click="togglecookiesGestion">Gestion des cookies</a>
  </footer>

  <div class="popin-container p-sm-4" id="popinMission" v-if="showMission">
    <div class="popin px-3 px-sm-4 px-md-5">
      <div class="mission">
        <h1>Votre mission</h1>
        <div class="text-left mb-5">
          <p class=""><strong>{{ character.name }} vous a donné rendez-vous sur son exploitation car {{ character.pronom }} commence sa fenaison :</strong></p>
          <ul>
            <li>Notre jeune {{ character.metier }} veut faucher une prairie mixte contenant un mélange de graminées.</li>
            <li>C’est la deuxième coupe de la saison et la météo est moyenne.</li>
            <li>Les prévisions pour la semaine à venir sont les suivantes :</li>
          </ul>

          <table class="meteo-mobile mb-5 d-md-none">
            <tr>
              <th></th>
              <th></th>
              <th>Tempér.</th>
              <th>Humidité</th>
            </tr>
            <tr>
              <td>Lundi</td>
              <td><img src="@/assets/img/icon-meteo-1@2x.png" width="70" class="img-fluid" alt=""></td>
              <td>15°</td>
              <td>50%</td>
            </tr>
            <tr>
              <td>Mardi</td>
              <td><img src="@/assets/img/icon-meteo-2@2x.png" width="70" class="img-fluid" alt=""></td>
              <td>18°</td>
              <td>75%</td>
            </tr>
            <tr>
              <td>Mercredi</td>
              <td><img src="@/assets/img/icon-meteo-4@2x.png" width="70" class="img-fluid" alt=""></td>
              <td>15°</td>
              <td>80%</td>
            </tr>
            <tr>
              <td>Jeudi</td>
              <td><img src="@/assets/img/icon-meteo-4@2x.png" width="70" class="img-fluid" alt=""></td>
              <td>14°</td>
              <td>85%</td>
            </tr>
            <tr>
              <td>Vendredi</td>
              <td><img src="@/assets/img/icon-meteo-3@2x.png" width="70" class="img-fluid" alt=""></td>
              <td>14°</td>
              <td>75%</td>
            </tr>
            <tr>
              <td>Samedi</td>
              <td><img src="@/assets/img/icon-meteo-1@2x.png" width="70" class="img-fluid" alt=""></td>
              <td>16°</td>
              <td>74%</td>
            </tr>
            <tr>
              <td>Dimanche</td>
              <td><img src="@/assets/img/icon-meteo-5@2x.png" width="70" class="img-fluid" alt=""></td>
              <td>17°</td>
              <td>60%</td>
            </tr>
            <tr>
              <td>Lundi</td>
              <td><img src="@/assets/img/icon-meteo-5@2x.png" width="70" class="img-fluid" alt=""></td>
              <td>15°</td>
              <td>90%</td>
            </tr>
          </table>

          <table class="meteo-desktop mb-5 d-none d-md-table">
            <tr>
              <th></th>
              <th>Lundi</th>
              <th>Mardi</th>
              <th>Mercredi</th>
              <th>Jeudi</th>
              <th>Vendredi</th>
              <th>Samedi</th>
              <th>Dimanche</th>
              <th>Lundi</th>
            </tr>
            <tr>
              <th></th>
              <td><img src="@/assets/img/icon-meteo-1@2x.png" width="70" class="img-fluid" alt=""></td>
              <td><img src="@/assets/img/icon-meteo-2@2x.png" width="70" class="img-fluid" alt=""></td>
              <td><img src="@/assets/img/icon-meteo-4@2x.png" width="70" class="img-fluid" alt=""></td>
              <td><img src="@/assets/img/icon-meteo-4@2x.png" width="70" class="img-fluid" alt=""></td>
              <td><img src="@/assets/img/icon-meteo-3@2x.png" width="70" class="img-fluid" alt=""></td>
              <td><img src="@/assets/img/icon-meteo-1@2x.png" width="70" class="img-fluid" alt=""></td>
              <td><img src="@/assets/img/icon-meteo-5@2x.png" width="70" class="img-fluid" alt=""></td>
              <td><img src="@/assets/img/icon-meteo-5@2x.png" width="70" class="img-fluid" alt=""></td>
            </tr>
            <tr>
              <th>Tempér.</th>
              <td>15°</td>
              <td>18°</td>
              <td>15°</td>
              <td>14°</td>
              <td>14°</td>
              <td>16°</td>
              <td>17°</td>
              <td>15°</td>
            </tr>
            <tr>
              <th>Humidité</th>
              <td>50%</td>
              <td>75%</td>
              <td>80%</td>
              <td>85%</td>
              <td>75%</td>
              <td>74%</td>
              <td>60%</td>
              <td>90%</td>
            </tr>
          </table>

          <p>La fenêtre est de 7 jours sans pluie, mais l’hygrométrie de l’air restera assez élevée : les journées ne sont donc pas les plus favorables pour un séchage rapide.</p>
          <p>Ces conditions météorologiques ne sont pas optimales pour {{ character.name }}. En effet, {{ character.pronom }} a l’habitude de démarrer la fauche lorsque la météo annonce au moins 3 jours d’ensoleillement et une perspective correcte pour les 5 jours à suivre. <strong>Comme chaque année,</strong> {{ character.pronom }} vise un fourrage riche en nutriments et souhaite faucher son foin au stade de début d’épiaison (foin jeune). Ses graminées sont à maturité, il faut démarrer la fauche !</p>
          <p><strong>Néanmoins, pas question de distribuer un foin poussiéreux et brun comme l’année précédente. {{ character.pronomMaj }} a besoin de vos conseils sur les pratiques à adopter. Tout au long du processus, faites-lui profiter de votre expérience !</strong></p>
        </div>

        <div class="mb-5">
          <a href="#" v-on:click="toggleMission" class="btn btn-secondary">C’EST PARTI !</a>
        </div>

      </div>
    </div>
  </div>

  <div class="popin-container p-sm-4" id="popinSupport" v-if="showSupport">
    <div class="popin px-3 px-sm-4 px-md-5">
      <div class="support">
        <h1>LE CRÉDIT AGRICOLE VOUS ACCOMPAGNE</h1>
        <div class="container">
          <div class="row mb-5">
            <div class="col-sm-6 d-flex align-items-center mb-1 mb-sm-0" id="visuSupport">
              <img class="img-fluid" src="@/assets/img/visu-ca-accompagne@2x.png" />
            </div>
            <div class="col-sm-6 d-flex align-items-center mb-1 mb-sm-0">
              <h2 style="text-transform: none;background: #F4F5EF;" class="px-4 py-5">Le Groupe Crédit Agricole accorde une importance particulière à la prévention des risques pour tous ses clients.</h2>
            </div>
          </div>
        </div>
        <div class="text-left mb-5">
          <p>Le dispositif « Parlons Fourrage » que nous proposons sensibilise sur les pratiques permettant de diminuer les risques d’échauffements de la fenaison jusqu’au stockage. Les échauffements altèrent la qualité des fourrages distribués à vos troupeaux et au-delà peuvent générer des incendies aux conséquences importantes pour votre exploitation.</p>
          <p>Conscients de la diversité des prairies et des besoins qui impactent les conduites de production de fourrages, nous avons fait le choix de vous donner les conseils adaptés aux pratiques les plus courantes en France.</p>
          <p><strong>Ce dispositif n’a pas vocation à se substituer à l’avis ni aux conseils d’un professionnel. Pour plus d’informations, contactez votre conseiller.</strong></p>
        </div>

        <div class="mb-5">
          <a href="#" v-on:click="toggleSupport" class="btn btn-secondary">C’EST PARTI !</a>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
// 1 - importation tag_commander_wrapper
import TC_Wrapper from "vue-tag-commander";

export default {
  name: "App",
  data(){
    return{
      menuOn: false,
      showMission: false,
      showSupport: false,
      character: {'name': 'Joris/ Flora', 'pronom': 'il/ elle', 'metier': 'agriculteur(trice)', 'pronomMaj': 'Il/ elle'},
      oldWrapper: {}
    }
  },
  mounted() {
  },
  methods:{
    toggleHome(){
        // ajout variables votre_mission
        const wrapper = TC_Wrapper.getInstance();

        //debug tag_commander
        //wrapper.setDebug(true);

        wrapper.setTcVars({
          environnement : 'dev',
          page_chapter1 : '',
          page_chapter2 : '',
          page_chapter3 : '',
          page : 'accueil'
        });
    },
    toggleMentions(){
        const wrapper = TC_Wrapper.getInstance();

        wrapper.setTcVars({
          environnement : 'dev',
          page_chapter1 : '',
          page_chapter2 : '',
          page_chapter3 : '',
          page : 'mentions_legales'
        });
    },
    toggleProtection(){
        const wrapper = TC_Wrapper.getInstance();

        wrapper.setTcVars({
          environnement : 'dev',
          page_chapter1 : '',
          page_chapter2 : '',
          page_chapter3 : '',
          page : 'protection_des_donnees_personnelles'
        });
    },
    toggleCookies(){
        const wrapper = TC_Wrapper.getInstance();

        wrapper.setTcVars({
          environnement : 'dev',
          page_chapter1 : '',
          page_chapter2 : '',
          page_chapter3 : '',
          page : 'politique_des_cookies'
        });
    },
    togglecookiesGestion(){
        const wrapper = TC_Wrapper.getInstance();

        wrapper.setTcVars({
          environnement : 'dev',
          page_chapter1 : '',
          page_chapter2 : '',
          page_chapter3 : '',
          page : 'gestion_des_cookies'
        });
    },
    toggleMenu(){
      if (this.menuOn) {
        this.menuOn = false;
      } else {
        this.menuOn = true;
      }
    },
    toggleMission(){

      if (this.showMission) {

        this.showMission = false;

        this.setOldWrapper();


      } else {
        // ajout variables votre_mission
        //debug tag_commander
        //wrapper.setDebug(true);

        this.getOldWrapper();

        const wrapper = TC_Wrapper.getInstance();

        wrapper.setTcVars({
          environnement: 'dev',
          page_chapter1: '',
          page_chapter2: '',
          page_chapter3: '',
          page: 'votre_mission'
        });

        this.showMission = true;
        this.showSupport = false;
        this.menuOn = false;

        if (this.$store.state.characterChoice == 'Joris') {
          this.character = {'name': this.$store.state.characterChoice, 'pronom': 'il', 'metier': 'agriculteur', 'pronomMaj': 'Il'};
        } else if (this.$store.state.characterChoice == 'Flora') {
          this.character = {'name': this.$store.state.characterChoice, 'pronom': 'elle', 'metier': 'agricultrice', 'pronomMaj': 'Elle'};
        }
      }

    },
    toggleSupport(){

      if (this.showSupport) {
        this.showSupport = false;
        this.setOldWrapper();
      } else {
        this.getOldWrapper();
        // ajout variables tag_commander_le_credit_vous_accompagne
        const wrapper = TC_Wrapper.getInstance();
        wrapper.setTcVars({
          environnement : 'dev',
          page_chapter1 : '',
          page_chapter2 : '',
          page_chapter3 : '',
          page : 'le_credit_agricole_vous_accompagne'
        });
        this.showSupport = true;
        this.showMission = false;
        this.menuOn = false;
      }
    },
    setOldWrapper() {
      if (this.oldWrapper.page) {
        const wrapper = TC_Wrapper.getInstance();
        wrapper.setTcVars({
          environnement: this.oldWrapper.environnement,
          page_chapter1: this.oldWrapper.page_chapter1,
          page_chapter2: this.oldWrapper.page_chapter2,
          page_chapter3: this.oldWrapper.page_chapter3,
          page: this.oldWrapper.page
        });
      }
    },
    getOldWrapper() {
      this.oldWrapper.environnement = window.tc_vars['environnement'];
      this.oldWrapper.page_chapter1 = window.tc_vars['page_chapter1'];
      this.oldWrapper.page_chapter2 = window.tc_vars['page_chapter2'];
      this.oldWrapper.page_chapter3 = window.tc_vars['page_chapter3'];
      this.oldWrapper.page = window.tc_vars['page'];
    }
  }
};
</script>


<style>

html{
  font-size:62.5%;
  min-height:100%;
}

body {
  min-height: 100%;
  font-size: 1.4rem;
  font-family: 'Roboto Condensed', sans-serif;
  min-width: 320px;
}

body {
  background: url(./assets/img/deco-foin@2x.jpg) no-repeat center bottom;
  background-size: 100% auto;
}

#app {
  text-align: center;
  color: #006A4E;
  min-height:100vh;
  display:flex;
  flex-direction:column;
  align-items: center;
}

#nav {
  padding: 2rem 0;
  position: relative;
  font-size: 1.8rem;
  min-height: calc( 30px + 4rem);
  width: 100%;
}

#nav .logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

#nav .btn-menu {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background: no-repeat center center;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg id='ICON_menu' data-name='ICON menu' xmlns='http://www.w3.org/2000/svg' width='35' height='22' viewBox='0 0 35 22'%3E%3Crect id='Rectangle_284' data-name='Rectangle 284' width='35' height='5' rx='2.5' fill='%2399c21c'/%3E%3Crect id='Rectangle_285' data-name='Rectangle 285' width='35' height='5' rx='2.5' transform='translate(0 8.5)' fill='%2399c21c'/%3E%3Crect id='Rectangle_286' data-name='Rectangle 286' width='35' height='5' rx='2.5' transform='translate(0 17)' fill='%2399c21c'/%3E%3C/svg%3E%0A");
}

#nav .menu {
  display: none;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(229,238,197,1) 100%);
  border-radius: 20px;
  border: 1px solid #99C21C;
  position: relative;
  padding: 30px 0;
  letter-spacing: -1px;
}

#nav .btn-close {
  cursor: pointer;
  content: '';
  position: absolute;
  top: calc(15px + 2rem);
  left: 15px;
  width: 30px;
  height: 30px;
  background: no-repeat center center;
  background-size: 20px 20px;
  background-image: url("data:image/svg+xml,%3Csvg id='ICON_fermer' data-name='ICON fermer' xmlns='http://www.w3.org/2000/svg' width='18.507' height='18.507' viewBox='0 0 18.507 18.507'%3E%3Cpath id='Union_2' data-name='Union 2' d='M14.931,17.893,9.253,12.215,3.576,17.893A2.094,2.094,0,0,1,.614,14.931L6.291,9.253.614,3.576A2.094,2.094,0,0,1,3.576.614L9.253,6.291,14.931.614a2.094,2.094,0,0,1,2.962,2.962L12.215,9.253l5.678,5.678a2.094,2.094,0,1,1-2.962,2.962Z' fill='%2399c21c'/%3E%3C/svg%3E%0A");
}

/*
#nav .menu:hover,
#nav .btn-menu:focus ~ .btn-close,
#nav .btn-menu:focus + .menu {
  display: block;
}
*/

#nav .menu span {
  display: block;
  margin: 5px auto;
  height: 0;
  width: 30px;
  border-bottom: 2px solid #000;
  overflow: hidden;
}

#nav .menu a {
  font-weight: bold;
  color: #006A4E;
  text-transform: uppercase;
  text-decoration: none;
}


#nav .menu a:hover,
#nav .menu a.router-link-exact-active {
  color: #99C21C;
}

.btn {
  font-size: 15px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  border: 0;
  min-width: 130px;
  padding: 5px 20px;
  text-transform: uppercase;
}

.btn-primary {
  color: white;
  background-color: #e00817;
}

.btn-primary:hover,
.btn-primary:focus {
  color: white;
  background-color: #136747;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #041b12;
}

.footer a {
  color: #1B9787;
  padding: 0 1rem;
}

.popin-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0rem;
  min-height: 100%;
}

.popin-container::before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
}

.popin {
  background: white;
  padding: 6rem 1rem 1rem 1rem;
  text-align: center;
  position: relative;
}

.popin .btn {
  font-size: 1.3rem;
}

h1 {
  color: #4DBCAD;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-size: 2.2rem;
}

.border-title::after,
h1::after {
  content: '';
  display: block;
  margin: 1rem auto 0 auto;
  width: 60px;
  height: 3px;
  background: #4DBCAD;
}

h2 {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.border-title::after {
  background: #006A4E;
}

.text-big {
  font-size: 1.6rem;
}

.support,
.mission {
  max-width: 700px;
  margin: 0 auto;
}

.mission table {
  width: 100%;
  text-align: center;
}

.support .btn-secondary::after,
.mission .btn-secondary::after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 2rem;
  top: 2rem;
  background: url("assets/img/icon-close@2x.png") no-repeat center center;
  background-size: contain;
}

.meteo-mobile td {
  border-right: 1px solid #707070;
}

.meteo-desktop tr th,
.meteo-desktop tr td {
  border-bottom: 1px solid #707070;
  padding: .5rem 0;
}

.meteo-desktop tr:first-child th,
.meteo-desktop tr:last-child th,
.meteo-desktop tr:first-child td,
.meteo-desktop tr:last-child td {
  border-bottom: 0;
}

/* Small devices (mobiles, 576px and up) */

@media (min-width: 576px) {
  #visuSupport img {
    position: relative;
    width: 120%;
    top: -1rem;
    left: -1rem;
    z-index: 1;
    max-width: none;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  #nav {
    padding: 3rem 0 3rem 0;
    min-height: 0;
  }

  #nav .logo {
    left: 0;
    transform: translateY(-50%);
  }

  #nav .btn-menu {
    display: none;
  }

  #nav .menu {
    display: block !important;
    background: 0;
    border: 0;
    padding: 0;
    padding-left: 46px;
    position: static;
  }

  #nav .btn-close {
    display: none !important;
  }

  #nav .menu span {
    display: inline;
    margin: 0;
    border: 0;
    width: auto;
    height: auto;
  }

  #visuSupport img {
    width: 117%;
    top: -1rem;
    left: -1rem;
  }
}


</style>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import TC_Wrapper from "vue-tag-commander";

const wrapper = TC_Wrapper.getInstance();
// Set debug for development purpose if needed
//wrapper.setDebug(true);
// Add TC Containers
Promise.all([
  wrapper.addContainer(
    "container_head",
    "https://cdn.tagcommander.com/5793/tc_CreditAgricoleAssurances_20.js",
    "head"
  )
]).then(() => {
  var options = {
    exclusions: [
        "datastorage",
        "deduplication",
        "internalvars",
        "privacy"
    ]
};
  wrapper.reloadAllContainers(options);
  createApp(App)
    .use(store)
    .use(router)
    .mount("#app");
});

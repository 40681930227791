import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/survey",
    name: "Survey",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Survey.vue")
  },
  {
    path: "/quiz",
    name: "Quiz",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Quiz.vue")
  },
  {
    path: "/results",
    name: "Results",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Results.vue")
  },
  {
    path: "/advice",
    name: "Advice",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Advice.vue")
  },
  {
    path: "/mentions",
    name: "Mentions",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Mentions.vue")
  },
  {
    path: "/confidentialite",
    name: "Confidentialite",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Confidentialite.vue")
  },
  {
    path: "/cookies",
    name: "Cookies",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Cookies.vue")
  }
];




const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    else {
      // always scroll to top
      return { top: 0 }
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;

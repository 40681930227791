import { createStore } from "vuex";

export default createStore({
  state: {
    //Les states sont accessibles par toutes les pages
    rightAnswers: 0,
    updatedQuiz: [],
    characterChoice: "",
    idAdvice: 0,
  },
  getters:{
    //Permet donc de récupérer des states mais avec modifications
  },
  mutations: {
    //permet de changer le state
    countRightAnswers(state, newValue) {
      state.rightAnswers = newValue ;
    },
    updatingQuiz(state, newValue) {
      state.updatedQuiz = newValue;
    },
    updatingCharacters(state, newValue) {
      state.characterChoice = newValue;
    },
    updatingIdAdvice(state, newValue) {
      state.idAdvice = newValue;
    },
  },
  actions: {

  },
  modules: {

  }
});